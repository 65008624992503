// Environment for remote production server (npm run build)

const server = 'https://api.deflaggr.com/';
const imgServer = 'https://userimg.deflaggr.com/';

export const environment = {
	production: true,
	API: {
		endpoint: `${server}v3/`
	},
	avatarPath: `${imgServer}avatars/`,
	eventPicPath: `${imgServer}eventpics/`
};
